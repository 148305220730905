






















import PaginationTs from './PaginationTs';

export default class Pagination extends PaginationTs {}
