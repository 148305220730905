
























































import { HarvestStatisticsPanelTs } from './HarvestStatisticsPanelTs';
export default class HarvestStatisticsPanel extends HarvestStatisticsPanelTs {}
