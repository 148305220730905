
















import { NavigationTabsTs } from './NavigationTabsTs';

export default class NavigationTabs extends NavigationTabsTs {}
