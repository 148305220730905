























// external dependencies
import { Component, Vue } from 'vue-property-decorator';

// child components
import NavigationTabs from '@/components/NavigationTabs/NavigationTabs.vue';
import HarvestStatisticsPanel from '@/components/HarvestStatisticsPanel/HarvestStatisticsPanel.vue';
import HarvestedBlocksList from '@/views/pages/harvesting/HarvestedBlocksList/HarvestedBlocksList.vue';

@Component({
    components: { NavigationTabs, HarvestStatisticsPanel, HarvestedBlocksList },
    data() {
        return {};
    },
})
export default class HarvestingDashboardPage extends Vue {}
